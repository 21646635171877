import classNames from "classnames"
import React, { useContext, useRef } from "react"

import { Color } from "../../../../constants/V2/color"
import DownloadAppButton from "../../../elements/V2/Buttons/DownloadAppButton"
import PillButton from "../../../elements/V2/Buttons/PillButton"
import TextButton from "../../../elements/V2/Buttons/TextButton"
import Callout from "../../../elements/V2/Callout"
import GatsbyStoryblokImage from "../../../elements/V2/GatsbyStoryblokImage"
import Section from "../../../elements/V2/Section"
import StickyContainer from "../../../elements/V2/StickyContainer"
import Typography from "../../../elements/V2/Typography"
import { NavigationV2Context } from "../../Navigation/context"

import { useElementObserver } from "@hooks/V2/useElementObserver"
import {
  getBackgroundColorClass,
  getAccentColor,
  getGradientFromColorClass,
  getGradientToColorClass,
} from "@utils/V2/color"
import { useIsBreakpoint } from "@utils/V2/screen"

type Props = {
  headingText: string
  bodyText: string
  superscriptText?: string
  primaryBackgroundColor: Color
  secondaryBackgroundColor?: Color
  primaryButtonText?: string
  primaryButtonLink?: string
  primaryButtonMobileLink?: string
  primaryButtonBorderColor: Color
  secondaryButtonText: string
  secondaryCTATextColor: Color.White | Color.Charcoal
  secondaryButtonLink: string
  imageUrl: string
  imageAlt: string
  showCallout: boolean
  showDownloadAppButton: boolean
} & Partial<Storyblok.CTATrackingEvent>

const HalfHero = ({
  headingText,
  bodyText,
  superscriptText,
  primaryBackgroundColor,
  secondaryBackgroundColor,
  primaryButtonText,
  primaryButtonLink,
  primaryButtonMobileLink,
  primaryButtonBorderColor,
  secondaryButtonText,
  secondaryCTATextColor = Color.Charcoal,
  secondaryButtonLink,
  imageUrl,
  imageAlt,
  showCallout = false,
  showDownloadAppButton = false,
  trackingEvent,
  trackingEventKey,
  trackingEventValue,
  ...props
}: Props) => {
  const navigationContext = useContext(NavigationV2Context)
  const textContainerRef = useRef(null)
  const { isVisible: isCtaVisible, startObserving } =
    useElementObserver(textContainerRef)
  const accentColor = getAccentColor(primaryBackgroundColor)
  const isMobile = useIsBreakpoint("mobile")

  if (isMobile) {
    startObserving()
  }

  return (
    <div
      className={classNames(
        "-mb-lg-v2 pb-lg-v2 grid grid-cols-2 items-center",
        !secondaryBackgroundColor
          ? getBackgroundColorClass(primaryBackgroundColor)
          : [
              "bg-gradient-to-br",
              getGradientFromColorClass(primaryBackgroundColor),
              getGradientToColorClass(secondaryBackgroundColor),
            ]
      )}
      {...props}
    >
      <Section
        className={classNames(
          "col-span-full flex flex-col pb-32-v2 pt-72-v2 sm-v2:col-start-1 sm-v2:col-end-2 sm-v2:pb-72-v2"
        )}
      >
        {superscriptText && (
          <Typography
            text={superscriptText}
            font="grotesk"
            weight="book"
            size="subscript-lg"
            color={accentColor}
            className="mb-8-v2"
          />
        )}
        {showCallout && (
          <Callout
            color={getAccentColor(primaryBackgroundColor)}
            classname="mt-8-v2 mb-16-v2"
          />
        )}
        <div className="flex flex-col gap-24-v2">
          <Typography
            text={headingText}
            font="grotesk"
            weight="medium"
            size="display1"
            color={accentColor}
            element="h1"
          />
          <Typography
            text={bodyText}
            font="grotesk"
            weight="book"
            size="body-lg"
            color={accentColor}
          />
        </div>
        <div
          ref={textContainerRef}
          className="mt-20-v2 flex flex-col items-center gap-24-v2 sm-v2:mt-40-v2 sm-v2:flex-row sm-v2:items-baseline"
        >
          {showDownloadAppButton ? (
            <DownloadAppButton
              text={primaryButtonText || "Download App"}
              color={primaryButtonBorderColor}
            />
          ) : primaryButtonText && primaryButtonLink ? (
            <PillButton
              size="large"
              style="solid"
              text={primaryButtonText}
              color={primaryButtonBorderColor}
              linkUrl={primaryButtonLink}
              mobileLinkUrl={primaryButtonMobileLink}
              trackingEvent={trackingEvent}
              trackingEventKey={trackingEventKey}
              trackingEventValue={trackingEventValue}
            />
          ) : null}

          <TextButton
            style="text"
            text={secondaryButtonText}
            linkUrl={secondaryButtonLink}
            color={secondaryCTATextColor}
          />
        </div>
        <StickyContainer
          isVisible={
            typeof isCtaVisible === "boolean" &&
            !isCtaVisible &&
            !navigationContext.isMobileMenuOpen
          }
        >
          {showDownloadAppButton ? (
            <DownloadAppButton
              text={primaryButtonText || "Download app"}
              color={primaryButtonBorderColor}
            />
          ) : primaryButtonText && primaryButtonLink ? (
            <PillButton
              size="large"
              style="solid"
              text={primaryButtonText}
              color={primaryButtonBorderColor}
              linkUrl={primaryButtonLink}
              mobileLinkUrl={primaryButtonMobileLink}
              trackingEvent={trackingEvent}
              trackingEventKey={trackingEventKey}
              trackingEventValue={trackingEventValue}
            />
          ) : null}
        </StickyContainer>
      </Section>
      <>
        <GatsbyStoryblokImage
          className="col-span-full mb-0 mt-40-v2 sm-v2:col-start-2 sm-v2:col-end-3 sm-v2:my-80-v2 md-v2:my-96-v2"
          aspectRatio="1:1"
          loadType="eager"
          image={imageUrl}
          alt={imageAlt || "Hero image"}
          quality={80}
        />
      </>
    </div>
  )
}

export default HalfHero
